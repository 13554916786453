import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ResultDetail from '../../components/results/result-detail'
import ResultDetailGql from '../../components/results/result-detail-gql'
import { Box } from '@mui/system'
import ContentContainer from '../../components/content-container'

const SecondPage = ({ params }) => (
  <>
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <Layout>
        <ContentContainer>
          <Seo title="Resultado page" />
          {/* <ResultDetail resultId={params.resultId} /> */}
          <Link to="/">Go back to the homepage</Link>
        </ContentContainer>
      </Layout>
    </Box>
  </>
)

export default SecondPage
